<template>
  <b-field :label="isInputType(field) ? field.label : null" :message="message" :type="type">
    <checkbox
      v-if="field.type === 'checkbox'"
      v-model="model"
      :disabled="disabled"
      :name="field.name"
      :required="field.required"
      @input="$emit('input', model)"
      >{{ field.label }}
    </checkbox>
    <b-datetimepicker
      v-else-if="field.type === 'datetime'"
      ref="dynamicFieldDateTimePicker"
      v-model="model"
      editable
      icon="calendar-today"
      locale="nl-NL"
      position="is-bottom-left"
      @input="$emit('input', model)"
    />

    <b-datepicker
      v-else-if="field.type === 'date'"
      v-model="model"
      editable
      icon="calendar-today"
      locale="nl-NL"
      position="is-bottom-left"
      @input="onDateUpdate"
    />

    <b-select
      v-else-if="field.type === 'select'"
      :id="field.name"
      v-model="model"
      expanded
      :required="field.required"
      @input="$emit('input', model)"
    >
      <option v-for="option in field.options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </b-select>
    <b-input
      v-else
      v-model="model"
      :required="field.required"
      :type="field.type"
      @input="$emit('input', model)"
    ></b-input>
  </b-field>
</template>

<script>
  import Checkbox from './Checkbox';
  import moment from 'moment';

  export default {
    components: { Checkbox },
    props: {
      field: Object,
      message: String,
      type: String,
      value: null,
      disabled: Boolean,
    },
    data() {
      let model = this.value;
      const isDateTypeField = ['datetime', 'date'].includes(this.field.type);

      if (isDateTypeField && model && !(model instanceof Date)) model = moment(model).toDate();

      return {
        model,
        isDateTypeField,
      };
    },
    mounted() {
      if (this.isDateTypeField && this.model) {
        this.$emit('input', moment(this.model).format('YYYY-MM-DD'));
      } else {
        this.$emit('input', this.model);
      }
    },
    methods: {
      isInputType(field) {
        return field.type !== 'checkbox' && !this.isDateTypeField;
      },

      setDefault() {
        if (!this.model && this.field.default) {
          let value = this.field.default;

          if (this.isDateTypeField && value && !(value instanceof Date)) value = moment(value).toDate();

          this.model = value;

          if (this.isDateTypeField) {
            this.$emit('input', moment(this.model).format('YYYY-MM-DD'));
          } else {
            this.$emit('input', this.model);
          }
        }
      },

      onDateUpdate(value) {
        this.model = moment(value).toDate();
        this.$emit('input', moment(this.model).format('YYYY-MM-DD'));
      },
    },
    created() {
      this.setDefault();
    },
  };
</script>
