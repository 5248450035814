import Keycloak from 'keycloak-js';
import { CONFIG_KEYCLOAK_REALM } from '@/config';

/** @type {import('keycloak-js').KeycloakConfig} */
const KEYCLOAK_CONFIG = {
  url: 'https://auth.innosend.eu',
  realm: CONFIG_KEYCLOAK_REALM,
  clientId: 'innosend-app',
};

/** @type {import('keycloak-js').KeycloakInitOptions} */
const KEYCLOAK_INIT_OPTIONS = {
  onLoad: 'login-required',
  checkLoginIframe: false,
};

export const keycloak = new Keycloak(KEYCLOAK_CONFIG);

export async function initKeycloak() {
  await keycloak.init(KEYCLOAK_INIT_OPTIONS);
}
