<template>
  <form v-if="label" id="ticket-form" @submit.prevent="createTicket">
    <b-loading v-model="isLoading" :is-full-page="false" />
    <div class="tw-container tw-max-w-none lg:tw-max-w-screen-2xl tw-p-8">
      <header>
        <nav>
          <h1 class="tw-text-3xl tw-font-bold tw-mb-4">Onderzoek aanvragen</h1>
          <p>Alleen complete zendingen kunnen worden onderzocht. Controleer hieronder of alle gegevens kloppen.</p>
        </nav>
      </header>
      <hr />
      <main>
        <div
          v-if="currentStage === 0"
          class="tw-grid tw-grid-rows-[auto,auto,auto,auto] tw-gap-8 sm:tw-grid-cols-2 lg:tw-grid-cols-3"
        >
          <section class="tw-row-span-full tw-space-y-4">
            <tracking-history :label="label" />
          </section>

          <section>
            <h2 class="tw-text-2xl tw-font-semibold">Ontvanger</h2>
            <div>
              <div
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <div class="tw-text-sm tw-text-neutral-500">Naam</div>
                <div class="tw-text-neutral-950">{{ label.name }}</div>
              </div>

              <div
                v-if="label.company_name"
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <div class="tw-text-sm tw-text-neutral-500">Bedrijf</div>
                <div class="tw-text-neutral-950">{{ label.company_name }}</div>
              </div>

              <div
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <div class="tw-text-sm tw-text-neutral-500">Adres</div>
                <div class="tw-text-neutral-950">
                  <address class="tw-not-italic">
                    {{ label.address_1 }} {{ label.housenumber }}<br />
                    <template v-if="label.address_2">{{ label.address_2 }}<br /></template>
                    {{ label.zipcode }} {{ label.city }}<br />
                    <country v-model="label.country" />
                  </address>
                </div>
              </div>

              <div
                v-if="label.email"
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <div class="tw-text-sm tw-text-neutral-500">E-mail</div>
                <div class="tw-text-neutral-950">
                  <a :href="`mailto:${label.email}`">{{ label.email }}</a>
                </div>
              </div>

              <div
                v-if="label.phone"
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <div class="tw-text-sm tw-text-neutral-500">Telefoonnummer</div>
                <div class="tw-text-neutral-950">{{ label.phone }}</div>
              </div>
            </div>
          </section>

          <section class="tw-space-y-4">
            <h2 class="tw-text-2xl tw-font-semibold">Zending</h2>
            <div class="tw-space-y-4">
              <div class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-4 tw-p-2 -tw-m-2">
                <p class="tw-text-sm tw-text-neutral-500">Verzendmethode</p>
                <p class="tw-text-neutral-950">
                  <courier :item="label" :show-warnings="false" />
                </p>
              </div>

              <div
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <p class="tw-text-sm tw-text-neutral-500">Status</p>
                <label-status :label="label" />
              </div>

              <div
                v-if="label.shipment && label.shipment.tracking_url"
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <p class="tw-text-sm tw-text-neutral-500">Track zending</p>
                <p class="tw-text-neutral-950">
                  <tracking-number
                    :tracking-number="label.shipment.tracking_number"
                    :tracking-url="label.shipment.tracking_url"
                  />
                </p>
              </div>

              <!-- TODO: Find a better solution for these nested properties. They don't look right -->
              <div
                v-if="
                  label?.shipment?.courier_fields && Object.keys(label.shipment.courier_fields).length && courierMeta
                "
                class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
              >
                <p class="tw-text-sm tw-text-neutral-500">Gekozen verzendopties</p>
                <ul v-if="courierFields.length" class="tw-grid tw-gap-1">
                  <li v-for="field in courierFields" :key="field.id" class="tag tw-w-fit is-primary">
                    {{ field.props?.label || field.name || field.id }}
                  </li>
                </ul>
                <div v-else class="value">Geen verzendopties gekozen</div>
              </div>
            </div>

            <div v-if="label.shipment" class="tw-max-h-96 tw-overflow-y-auto px-2 -tw-mx-2">
              <div v-for="(package_, idx) in label.shipment.packages" :key="`package-${idx}`" class="tw-space-y-2">
                <h3 class="tw-text-xl tw-font-semibold">
                  Pakket
                  <span v-if="label.shipment.packages.length > 1">{{ idx + 1 }}</span>
                </h3>

                <div class="tw-space-y-1">
                  <div
                    class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
                  >
                    <p class="tw-text-sm tw-text-neutral-500">Gewicht</p>
                    <p v-if="package_.weight" class="tw-text-neutral-950">{{ package_.weight }} gram</p>
                    <p v-else>–</p>
                  </div>
                  <div
                    v-if="package_.tracking_url"
                    class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
                  >
                    <p class="tw-text-sm tw-text-neutral-500">Tracking</p>
                    <tracking-number
                      :tracking-number="package_.tracking_number"
                      :tracking-url="package_.tracking_url"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2 class="tw-text-2xl tw-font-semibold">Order</h2>
            <div
              v-if="label.order_number"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <div class="tw-text-sm tw-text-neutral-500">Ordernummer</div>
              <div class="tw-text-neutral-950">{{ label.order_number }}</div>
            </div>

            <div
              v-if="label.order_date"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <div class="tw-text-sm tw-text-neutral-500">Orderdatum</div>
              <div class="tw-text-neutral-950">{{ label.order_date | formatTimestamp }}</div>
            </div>

            <div
              v-if="label.order_status"
              class="hover:tw-bg-primary/5 tw-transition-colors tw-rounded-lg tw-space-y-2 tw-pl-2 tw-py-2 -tw-ml-2"
            >
              <div class="tw-text-sm tw-text-neutral-500">Shop Status</div>
              <div class="tw-text-neutral-950">{{ label.order_status }}</div>
            </div>

            <div v-if="label.invoice_items">
              <b-collapse animation="slide" aria-id="invoice_items_collapse" :open="false">
                <template #trigger="props">
                  <a aria-controls="invoice_items_collapse" :aria-expanded="props.open" class="block">
                    <h2>
                      <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
                      Prijs en facturatie
                    </h2>
                  </a>
                </template>
                <div v-for="(price, idx) in label.invoice_items" :key="'invoice_item' + idx" class="block">
                  <v-with
                    v-slot="{ total }"
                    :total="price.parts.reduce((acc, value) => acc + parseFloat(value.unit_price), 0)"
                  >
                    <table class="table is-striped is-hoverable is-narrow is-fullwidth">
                      <thead>
                        <tr>
                          <th v-if="price.type === 'label_price'" colspan="2">Labelprijs</th>
                          <th v-else-if="price.type === 'surcharge'" colspan="2">Naheffing</th>
                          <th v-else-if="price.type === 'refund'" colspan="2">Terugbetaling</th>
                          <th v-else colspan="2">Onbekend factuurtype</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in price.parts" :key="'invoice_item' + idx + item.id">
                          <td>{{ item.description }}</td>
                          <td class="has-text-right">{{ item.unit_price | formatMoney }}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Totaal</th>
                          <th class="has-text-right">{{ total | formatMoney }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </v-with>
                </div>
              </b-collapse>
            </div>
          </section>

          <section v-if="this.label?.order_items?.length > 0" class="xl:tw-col-start-2 tw-col-span-full">
            <hr class="seperator" />
            <h2 class="title is-4">Orderregels</h2>
            <b-table :data="this.label.order_items" hoverable mobile-cards striped>
              <b-table-column v-slot="props" field="name" label="Naam">
                {{ props.row.name }}
              </b-table-column>
              <b-table-column v-slot="props" field="description" label="Omschrijving">
                {{ props.row.description }}
              </b-table-column>
              <b-table-column v-slot="props" field="quantity" label="Aantal" numeric>
                {{ props.row.quantity }}
              </b-table-column>
              <b-table-column v-slot="props" field="unit_price_inc_btw" label="Prijs per product" numeric>
                {{ props.row.unit_price_inc_btw | formatMoney(label.currency || 'EUR') }}
              </b-table-column>
              <b-table-column v-slot="props" field="weight" label="Gewicht per product" numeric>
                {{ humanizeUnit(props.row.weight, 'gram') }}
              </b-table-column>
              <b-table-column v-slot="props" field="category" label="Categorie">
                {{ props.row.category }}
              </b-table-column>
              <b-table-column v-slot="props" field="sku" label="SKU-code" numeric>
                {{ props.row.sku }}
              </b-table-column>
              <b-table-column v-slot="props" field="hs_code" label="HS-code" numeric>
                {{ props.row.hs_code }}
              </b-table-column>
              <b-table-column v-slot="props" field="product_country" label="Land van herkomst">
                <country :value="props.row.product_country" />
              </b-table-column>
            </b-table>
          </section>
        </div>
        <div v-else-if="currentStage === 1" class="tw-container">
          <b-field label="Selecteer reden">
            <b-select v-model="fields.subject" class="tw-w-fit" placeholder="Selecteer reden" required>
              <option v-for="subject in subjects" :key="subject" :value="subject">
                {{ subject }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Toelichting" message="Geef extra informatie die ons kan helpen bij het onderzoek">
            <b-input
              v-model="fields.message"
              placeholder="Bijvoorbeeld: de klant heeft niets ontvangen en volgens track en trace is de zending al een week in het sorteercentrum"
              required
              type="textarea"
            ></b-input>
          </b-field>

          <b-field
            label="Omschrijf de uiterlijke kenmerken van het pakket"
            message="Omschrijf de verpakking, kleur tape, vorm, en grootte van het pakket"
          >
            <b-input
              v-model="fields.external_properties"
              placeholder="Bijvoorbeeld: 20cm × 30cm × 50cm, wit en groene doos met transparante tape"
              required
              type="textarea"
            ></b-input>
          </b-field>

          <b-field
            label="Omschrijf de inhoud van het pakket"
            message="Geef aan wat de verzonder producten zijn, indien er geen orderregels bekend zijn."
          >
            <b-input
              v-model="fields.internal_properties"
              placeholder="Bijvoorbeeld: 1 doos boutjes, 1 doos moertjes, 2 stekkers, 2 snoeren"
              required
              type="textarea"
            ></b-input>
          </b-field>

          <b-field
            label="Inkoopwaarde pakket"
            message="Wij hebben de inkoopwaarde nodig om de mogelijke vergoeding te bepalen. Alleen de inkoopwaarde wordt vergoed."
          >
            <p class="control">
              <span class="button is-static">€</span>
            </p>
            <b-input v-model="fields.cost_price" expanded min="0" required step="0.01" type="number"></b-input>
          </b-field>

          <b-field
            label="Upload verkoopfactuur"
            message="Wij hebben de verkoopfactuur nodig om de zending en de inhoud van het pakket te controleren."
          >
            <b-upload v-model="files" drag-drop expanded multiple>
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="software-upload" size="is-large"> </b-icon>
                  </p>
                  <p>Sleep je bestand hier naartoe of klik om te uploaden.</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <div class="tags">
            <b-tag
              v-for="(file, idx) in files"
              :key="file.name"
              aria-close-label="Verwijder bestand"
              closable
              @close="files.splice(idx, 1)"
            >
              {{ file.name }}
            </b-tag>
          </div>
        </div>
      </main>
    </div>
    <form-page-footer>
      <button v-if="currentStage === 0" class="button" @click="$router.backOrDefault(defaultReturnRoute)">
        Annuleren
      </button>
      <button v-else class="button" @click="currentStage--">Terug</button>

      <button v-if="currentStage === 0" class="button is-primary" @click="goToNextStage">Doorgaan</button>
      <button
        v-else-if="currentStage === 1"
        class="button is-primary"
        :disabled="isSubmitting || isUvDeskEnabled"
        form="ticket-form"
        :loading="isSubmitting"
        type="submit"
      >
        Onderzoek aanvragen
      </button>
    </form-page-footer>
  </form>
</template>

<script>
  import Country from '@/components/properties/Country';
  import Courier from '@/components/properties/Courier';
  import FormPageFooter from '@/components/form/FormPageFooter';
  import { getBase64 } from '@/utils/functions';
  import LabelStatus from '@/components/properties/LabelStatus.vue';
  import TrackingNumber from '@/components/properties/TrackingNumber.vue';
  import TrackingHistory from '@/components/TrackingHistory.vue';
  import { CONFIG_UVDESK_ENABLED } from '@/config';

  export default {
    props: {},
    components: { Country, Courier, FormPageFooter, LabelStatus, TrackingNumber, TrackingHistory },
    data() {
      const subjects = [
        'Bezorgd maar niet ontvangen',
        'Zending staat vast in sorteercentrum',
        'Wanneer wordt het pakket bezorgd?',
        'Bewijs van levering',
        'Zending onterecht terug bij afzender',
        'Anders',
      ]; //what more?
      return {
        currentStage: 0,
        defaultReturnRoute: { name: 'dashboard', params: { activeTabName: 'labels' } },
        fields: {
          email: '',
          phone: '',
          message: '',
          cost_price: 0,
          internal_properties: '',
          external_properties: '',
          subject: subjects[0],
        }, // different default?
        files: [],
        isLoading: false,
        isOpen: 0,
        isSubmitting: false,
        label: null,
        labelId: this.$route.params.labelId,
        selectedPackage: null,
        subjects: subjects,
      };
    },
    computed: {
      courierMeta() {
        const courier = this.$store.getters['courier/courierById'](this.label.courier_id || this.label.service_id);
        return this.$store.state.courier.meta.find(c => c.class == courier.service_class);
      },

      isUvDeskEnabled() {
        return CONFIG_UVDESK_ENABLED;
      },

      isPhoneFilled() {
        if (this.label.phone)
          // phone is already filled in. No need to check fields
          return true;
        else return this.fields.phone !== '';
      },
      isEmailFilled() {
        if (this.label.email)
          // email is already filled in. No need to check fields
          return true;
        else return this.fields.email !== '';
      },
      courierFields() {
        let fields = [];
        if (this.courierMeta) {
          for (const fieldName in this.label.shipment.courier_fields) {
            const value = this.label.shipment.courier_fields[fieldName];
            if (value && value != 0) {
              fields.push({
                id: fieldName,
                props: this.courierMeta.order_fields.find(f => f.name == fieldName),
                value: value,
              });
            }
          }
        }
        return fields;
      },
    },
    methods: {
      humanizeUnit(value, unit) {
        return Intl.NumberFormat('nl-NL', { style: 'unit', unit }).format(value);
      },
      goToNextStage() {
        if (this.currentStage === 0) {
          //kinda unnecessary, as this method will only be used at 1 place. But you'll never know.
          if (!this.isPhoneFilled && !this.isEmailFilled) this.openToast('Vul email en telefoonnummer in.');
          else if (!this.isEmailFilled) this.openToast('Vul email in.');
          else this.currentStage += 1;
        }
      },
      openToast(message, type = 'is-danger') {
        this.$buefy.toast.open({
          message: message,
          type: type,
          duration: 5000,
        });
      },
      async createTicket() {
        this.isSubmitting = true;
        // TODO: Remove this as soon as backend supports multiple files
        if (this.files.length === 0) {
          this.openToast('Je moet een verkoopfactuur uploaden');
          this.isSubmitting = false;
          return;
        }

        if (this.files.length > 1) {
          this.openToast('Er kan maar 1 bestand op worden gestuurd');
          this.isSubmitting = false;
          return;
        }
        // Other validation is done through required and type attrs on the form fields
        const data = {
          label_id: this.label.id,
          attachments: await this.parseFiles(this.files),
          ...this.fields,
        };
        try {
          const result = await this.$store.dispatch('ticket/createTicket', data);
          if (result.status === 'success') return this.$router.push({ name: 'ticket-list' });
        } finally {
          this.isSubmitting = false;
        }
      },
      async parseFiles(files) {
        let parsed = [];
        if (files) {
          for (const file of files) {
            parsed.push({
              filename: file.name,
              base_64: await getBase64(file),
            });
          }
        }
        return parsed;
      },
    },
    async created() {
      this.isLoading = true;
      try {
        this.label = await this.$store.dispatch('label/getLabel', { labelId: this.labelId });
        this.selectedPackage = this.label.shipment?.packages?.[0];
      } finally {
        this.isLoading = false;
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.labelId = to.params.labelId;
      next();
    },
    filters: {
      formatValue(value) {
        if (value === true) return 'Ja';
        else if (value === false) return 'Nee';
        else if (!value) return '-';
        return value;
      },
    },
  };
</script>
