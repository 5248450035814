<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h2 class="modal-card-title">{{ $t('error_dialog_title') }}</h2>
    </header>
    <section v-if="error" class="modal-card-body">
      <div v-if="uniqueErrors > tooManyErrorsThreshold" class="content">
        {{ uniqueErrors }} verschillende fouten opgetreden sinds {{ error.timestamp | formatTimestamp('HH:mm:ss') }}.
        Probeer de pagina te herladen, of neem contact op met de klantenservice als het probleem zich blijft voortdoen.
      </div>
      <api-error-details v-else-if="error.type === 'ApiError'" :error="error" @close="close">
        <template #details>
          <p v-if="duplicateErrors > 1" class="is-size-7">
            {{ duplicateErrors }} keer voorgekomen sinds {{ error.timestamp | formatTimestamp('HH:mm:ss') }}
          </p>
        </template>
      </api-error-details>
      <error-details v-else @close="close">
        <p>{{ error.message }}</p>
        <template #details>
          <pre>{{ error.error.toString() }}</pre>
          <p v-if="duplicateErrors > 1" class="is-size-7">
            {{ duplicateErrors }} keer voorgekomen sinds {{ error.timestamp | formatTimestamp('HH:mm:ss') }}
          </p>
        </template>
      </error-details>
    </section>
    <footer class="modal-card-foot buttons is-right">
      <b-button v-if="canUserReport" @click="reportError">Feedback</b-button>
      <b-button type="is-primary" @click="close">OK</b-button>
    </footer>
  </div>
</template>

<script>
  import * as Sentry from '@sentry/vue';
  import { mapState } from 'vuex';
  import ApiErrorDetails from '@/components/errors/ApiErrorDetails';
  import ErrorDetails from '@/components/errors/ErrorDetails';
  import UserFeedbackDialog from './UserFeedbackDialog';

  export default {
    components: { ApiErrorDetails, ErrorDetails },
    props: {
      cancelText: String,
      confirmText: {
        type: String,
        default: 'OK',
      },
      onCancel: Function,
      onConfirm: Function,
    },
    computed: {
      ...mapState({
        errors: state => state.app.errors,
      }),

      tooManyErrorsThreshold() {
        return 3;
      },
      error() {
        if (this.errors.length > 0) return this.errors[0];
        return null;
      },
      duplicateErrors() {
        if (this.error) return this.errors.filter(e => e.hash == this.error.hash).length;
        return 0;
      },
      uniqueErrors() {
        return new Set(this.errors.map(e => e.hash)).size;
      },
    },
    methods: {
      reportError() {
        Sentry.withScope(() => {
          Sentry.setContext('errors', {
            duplicate: this.duplicateErrors,
            unique: this.uniqueErrors,
            total: this.errors.length,
          });
          const event_id = Sentry.captureException(this.error.error);
          this.$buefy.modal.open({
            parent: this,
            component: UserFeedbackDialog,
            hasModalCard: true,
            trapFocus: true,
            canCancel: ['escape', 'outside'],
            props: { event_id },
          });
        });
        this.close();
      },
      close() {
        if (this.error) this.$store.commit('app/deleteError', this.error);
        this.$emit('close');
      },
    },
  };
</script>
