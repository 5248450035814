<template>
  <form id="feedback-form" @submit.prevent="submit">
    <div class="modal-card is-wide">
      <header class="modal-card-head">
        <p class="modal-card-title">Feedback</p>
        <button aria-label="close" class="delete" @click="$parent.close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>Help Innosend te verbeteren door meer informatie te geven over deze foutmelding.</p>
        </div>
        <b-field label="Naam">
          <b-input v-model="fields.name" required></b-input>
        </b-field>
        <b-field label="E-mailadres">
          <b-input v-model="fields.email" required type="email"></b-input>
        </b-field>
        <b-field label="Wat gebeurde er? Beschrijf het zo gedetailleerd mogelijk.">
          <b-input v-model="fields.comments" maxlength="2048" required type="textarea"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <button class="button" type="button" @click="$parent.close()">Annuleren</button>
        <button class="button is-primary" form="feedback-form" type="submit">Feedback verzenden</button>
      </footer>
    </div>
  </form>
</template>

<script>
  import * as Sentry from '@sentry/vue';

  export default {
    props: {
      event_id: String,
    },
    data() {
      const organisation = this.$store.state.account.organisation;
      let fields = {
        name: organisation?.contact?.name,
        email: organisation?.contact?.email,
        comments: null,
      };
      return {
        fields,
      };
    },
    methods: {
      async submit() {
        try {
          Sentry.captureUserFeedback({
            name: this.fields.name,
            email: this.fields.email,
            comments: this.fields.comments,
          });
          this.$buefy.toast.open({
            message: 'Je feedback is verzonden. Dankjewel!',
            type: 'is-success',
            duration: 5000,
          });
        } catch (e) {
          this.$buefy.toast.open({
            message: 'Fout bij het verzenden van de feedback. Probeer het nog eens.',
            type: 'is-danger',
            duration: 5000,
          });
        } finally {
          this.$parent.close();
        }
      },
    },
  };
</script>
